
import React, { useState } from 'react';
import './App.css';
import { FaWifi, FaEye, FaEyeSlash, } from 'react-icons/fa';

function App() {
  const [device, setDevice] = useState(null);
  const [halfJson, setHalfJson] = useState("");
  const [wifiNetworks, setWifiNetworks] = useState([
    { ssid: 'wifi1' },
    { ssid: 'wifi2' },
    { ssid: 'wifi3' },
  ]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [passwords, setPasswords] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [btmsg, setBtmsg] = useState('');


  const requestBluetoothDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [0x00FF] }] // Using 0x00FF as the service UUID
      });
      console.log('Device Info:', {
        name: device.name,
        id: device.id,
        uuids: device.uuids
      });
      setDevice(device);
      setBtmsg(`${device.name} Paired Successfully, You can Connect now `)
    } catch (error) {
      console.error('Error requesting Bluetooth device:', error);
    }
  };


  const isAscii = (value) => {
    const asciiRegex = /^[\x20-\x7F]*$/; // This will match only printable ASCII characters
    return asciiRegex.test(value);
  }

  const isJson = (value) => {
    try {
      let combinedValue;
      if (isAscii(value)) {
        console.log("ASCII.string", value);
        if (value.trim().endsWith("]")) {
          console.log("last with ] before combining halfjson: ", halfJson);
          combinedValue = halfJson + value;
          console.log("combinedValue after last: ", combinedValue);
          setHalfJson(combinedValue);
          console.log("combined halfJson: ", halfJson);
          return [true, combinedValue]; // Attempt to parse the JSON
        }
        else if (value.charAt(0) === '[') {
          console.log("first with [  before combining halfjson: ", halfJson);
          combinedValue = (halfJson || '') + value;
          console.log("combinedValue after first: ", combinedValue);
          setHalfJson(combinedValue);
          console.log("combined halfJson: ", halfJson);
          return [false, null]
        }
        else {
          console.log("else: ", value);
          return [false, null];
        }
      }
      else {
        return [false, null];
      }
    } catch (e) {
      return [false, null];
    }
  }


  const connectToDevice = async () => {
    if (!device) return;
    try {
      setBtmsg('');
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(0x00FF); // Using 0x00FF as the service UUID
      const characteristic = await service.getCharacteristic(0xFF01); // Example characteristic UUID
      await characteristic.startNotifications();
      characteristic.addEventListener('characteristicvaluechanged', (event) => {
        const value = new TextDecoder().decode(event.target.value);
        console.log("event recieved value: " + value);
        let jsoncheck = isJson(value);
        console.log("jsoncheck", jsoncheck);
        console.log("halfJson", halfJson);
        if (jsoncheck[0]) {
          console.log("The value is valid JSON.");
          try {
            const networks = JSON.parse(jsoncheck[1]);
            console.log("The networks", networks);
            setWifiNetworks(networks);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.log("The value is not valid JSON.");
        }
      });
    } catch (error) {
      console.error('Error connecting to Bluetooth device:', error);
    }
  };

  const scanwifi = async () => {
    if (!device) return;
    const server = await device.gatt.connect();
    const service = await server.getPrimaryService(0x00FF); // Using 0x00FF as the service UUID
    const characteristic = await service.getCharacteristic(0xFF01); // Example characteristic UUID
    const response = await characteristic.readValue();
    console.log("res", response);
  }



  const handleConnectWiFi = async (ssid) => {
    const wifiDetails = {
      ssid: selectedNetwork ? selectedNetwork : "ABIOTS_MAIN",
      password: passwords[ssid]
    };
    console.log("wifi", JSON.stringify(wifiDetails));
    if (!device) return;
    try {
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(0x00FF); // Using 0x00FF as the service UUID
      const characteristic = await service.getCharacteristic(0xFF01); // Example characteristic UUID
      const encoder = new TextEncoder();
      const value = encoder.encode(JSON.stringify(wifiDetails));
      await characteristic.writeValue(value);
    } catch (error) {
      console.error('Error connecting to Wi-Fi:', error);
    }
  };


  const handleNetworkSelect = (ssid) => {
    if (ssid !== selectedNetwork) {
      setSelectedNetwork(ssid);
    }
  };

  const handlePasswordChange = (ssid, value) => {
    setPasswords({
      ...passwords,
      [ssid]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };




  return (
    <div className="app-container">
      <div className="device-container">
        <h1 className="device-title">Neaum Wifi Config</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button id='btn-1' onClick={requestBluetoothDevice}>search ESP32</button>
          <button id='btn-2' onClick={connectToDevice}>connect to esp32</button>
          <button id='btn-3' onClick={scanwifi}>scan wifis</button>
        </div>
        <span>{btmsg}</span>
      </div>
      <ul className="wifi-list">
        {wifiNetworks.length > 0 ? wifiNetworks.map((wifi, index) => (
          <li
            key={index}
            className={`wifi-item ${selectedNetwork === wifi.ssid ? 'selected' : ''}`}
            onClick={() => handleNetworkSelect(wifi.ssid)}
          >
            <div className="wifi-header">
              <div className='wifi-first'>
                <input
                  type="checkbox"
                  checked={selectedNetwork === wifi.ssid}
                  onChange={() => handleNetworkSelect(wifi.ssid)}
                />
                <span className="wifi-name">{wifi.ssid}</span>
              </div>
              <FaWifi style={{ marginRight: "4%" }} />
            </div>
            {selectedNetwork === wifi.ssid && (
              <>
                <div className="password-container">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={passwords[wifi.ssid] || ''}
                    onChange={(e) => handlePasswordChange(wifi.ssid, e.target.value)}
                    placeholder={`Enter password for ${wifi.ssid}`}
                  />
                  <span className="eye-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <button className="connect-button" onClick={() => handleConnectWiFi(wifi.ssid)}>Connect</button>
              </>
            )}
          </li>
        )) : (
          <li className="wifi-item">No networks available</li>
        )}
      </ul>
    </div>
  );
}

export default App;
